<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <h2 class="brand-text text-primary ml-1">Utilis</h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t("modules.auth.resetPassword") }} 🔒
                </b-card-title>
                <formulateForm
                    v-slot="{ isLoading, hasErrors }"
                    v-model="formData"
                    name="loginForm"
                    @submit="handleSubmit"
                >
                    <b-row alignV="center">
                        <b-col cols="12">
                            <formulateInput
                                autofocus
                                :label="$t('modules.systemSettings.mail.name')"
                                name="email"
                                placeholder="examples@mail.com"
                                type="email"
                                validation="required|email"
                            />
                        </b-col>
                    </b-row>

                    <b-row alignV="center">
                        <b-col cols="11" md="10">
                            <formulateInput
                                autofocus
                                :label="$t('modules.auth.newPassword')"
                                name="password"
                                placeholder="············"
                                :type="password1FieldType"
                                validation="required|max:255,length"
                            />
                        </b-col>
                        <b-col cols="1" md="2">
                            <feather-icon
                                class="cursor-pointer"
                                size="15"
                                :icon="password1ToggleIcon"
                                @click="togglePassword1Visibility"
                            />
                        </b-col>
                    </b-row>

                    <b-row alignV="center">
                        <b-col cols="11" md="10">
                            <formulateInput
                                :label="$t('modules.auth.passwordConfirmation')"
                                name="passwordConfirmation"
                                placeholder="············"
                                :type="password2FieldType"
                                validation="required|max:255,length|confirm:password"
                            />
                        </b-col>
                        <b-col cols="1" md="2">
                            <feather-icon
                                class="cursor-pointer"
                                size="15"
                                :icon="password2ToggleIcon"
                                @click="togglePassword2Visibility"
                            />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="d-flex justify-content-center">
                            <formulateErrors />
                        </b-col>
                    </b-row>

                    <b-row class="mt-2">
                        <b-col class="d-flex justify-content-center">
                            <formulateInput
                                :disabled="hasErrors || isLoading"
                                :label="
                                    $tc('modules.auth.createNewPassword', 1)
                                "
                                inputClass="btn btn-primary"
                                type="submit"
                            />
                        </b-col>
                    </b-row>

                    <p class="text-center mt-2">
                        <b-link :to="{ name: 'auth-login-v1' }">
                            <feather-icon icon="ChevronLeftIcon" />
                            {{ $t("modules.auth.backToLogin") }}
                        </b-link>
                    </p>
                </formulateForm>
            </b-card>
        </div>
    </div>
</template>

<script>
import { BCard, BCardTitle, BLink, BRow, BCol } from "bootstrap-vue"
import toastMessages from "@/views/components/utilis-spa/utilities/ToastMessages"

export default {
    components: {
        BCard,
        BCardTitle,
        BRow,
        BCol,
        BLink,
    },
    data() {
        return {
            formData: {
                password: "",
                passwordConfirmation: "",
            },
            password1FieldType: "password",
            password2FieldType: "password",
        }
    },
    computed: {
        password1ToggleIcon() {
            return this.password1FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon"
        },
        password2ToggleIcon() {
            return this.password2FieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon"
        },
    },
    methods: {
        togglePassword1Visibility() {
            this.password1FieldType =
                this.password1FieldType === "password" ? "text" : "password"
        },
        togglePassword2Visibility() {
            this.password2FieldType =
                this.password2FieldType === "password" ? "text" : "password"
        },
        async handleSubmit() {
            try {
                await this.$http.get("/sanctum/csrf-cookie")

                await this.$http.post("/password/reset", {
                    token: this.$route.params.token,
                    password: this.formData.password,
                    email: this.formData.email,
                    password_confirmation: this.formData.passwordConfirmation,
                })

                this.$toast(toastMessages.getSuccessMessage())
                this.$router.push({ path: "/login" })
            } catch (e) {
                this.$formulate.handle(e, "loginForm")
                this.$toast(toastMessages.getUnexpectedError())
            } finally {
                this.isLoading = false
            }
        },
    },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth";
</style>
